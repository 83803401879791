import React, { Component } from "react";
import { useState, useEffect } from "react";
import {
  getAttorneyByUser,
  deleteAttorney,
  getAttorneyInfo,
  getAttorneyVoidData,
} from "../../actions/attorneyAction";
import { getCSV } from "../../actions/adminAction";
import { AiOutlineDelete } from "react-icons/ai";
import { connect } from "react-redux";
import "./style.css";
import Nav from "../../components/Nav";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import {
  BiSolidUpArrow,
  BiSolidDownArrow,
  BiPlus,
  BiMinus,
} from "react-icons/bi";
import Confirm from "../../components/modals/confirm";
import { MDBBtn, MDBInputGroup } from "mdbreact";
import Select from "react-select";

function AttorneyConfirmPage(props) {
  const attorneySortItemList = [
    { name: "id", sortKey: "down", title: "單號" },
    { name: "auto_pic", sortKey: "down", title: "電" },
    { name: "manual_pic", sortKey: "down", title: "手" },
    { name: "void_pic", sortKey: "down", title: "退" },
    { name: "total_pic", sortKey: "down", title: "總" },
    { name: "user_group", sortKey: "down", title: "交單時間" },
    { name: "status", sortKey: "down", title: "狀態" },
  ];

  const stockSortItemList = [
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "gift_item", sortKey: "down", title: "紀念品" },
    { name: "acq_price", sortKey: "down", title: "收購價" },
    { name: "auto_pic", sortKey: "down", title: "收購" },
    { name: "manual_pic", sortKey: "down", title: "不收購" },
    { name: "void_pic", sortKey: "down", title: "退" },
    { name: "total_pic", sortKey: "down", title: "總" },
    { name: "submit_date", sortKey: "down", title: "交單時間" },
    { name: "note", sortKey: "down", title: "備註" },
    { name: "confirm", sortKey: "down", title: "狀態" },
  ];

  const [attorneyList, setAttorneyList] = useState([]);
  const [attorneySortSelect, setAttorneySortSelect] = useState("id");
  const [stockSortSelect, setStockSortSelect] = useState("stock_code");
  const [sortAttorneyList, setSortAttorneyList] =
    useState(attorneySortItemList);
  const [sortStockList, setSortStockList] = useState(stockSortItemList);
  const [stocklist, setStockList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedAttorneyId, setSelectedAttorneyId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [showVoidInfo, setShowVoidInfo] = useState(false);
  const [searchStockList, setSearchStockList] = useState([]);
  const [yearSelected, setYearSelected] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [voidInfo, setVoidInfo] = useState(null);
  const [selectedAttorney, setSelectedAttorney] = useState([]);
  const [selectAllAttorney, setSelectAllAttorney] = useState(false);
  const [showMultipleVoid, setShowMultipleVoid] = useState(false);
  const [isMultipleVoid, setIsMultipleVoid] = useState(false);

  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const currentYear = new Date().getFullYear();

  async function getAttorneyList() {
    const result = await getAttorneyByUser(localUser.id, localUser.accessToken);

    if (result != null) {
      let tempStocklist = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        tempStocklist.push({
          stock_code: element.stock_info[0].stock_code,
          stock_name: element.stock_info[0].stock_name,
          gift_item: element.stock_info[0].gift_item,
          acq_price: element.stock_info[0].acq_price,
          auto_pic: element.stock_info[0].auto_pic,
          manual_pic: element.stock_info[0].manual_pic,
          void_pic: element.stock_info[0].void_pic,
          id: element.id,
          auto_pic: element.stock_info[0].auto_pic,
          submit_date: element.submit_date,
          year: element.year,
          confirm: element.confirm,
          note: element.stock_info[0].note,
        });
      }
      setStockList(tempStocklist);
    }
  }

  const combineData = (attorneyInfo) => {
    const tempInfo = attorneyInfo.stock_info[0];
    return {
      ...tempInfo,
      id: attorneyInfo.id,
      submit_date: attorneyInfo.submit_date,
      confirm: attorneyInfo.confirm,
      year: attorneyInfo.year,
    };
  };

  function padId(id) {
    const strId = String(id);
    const paddingLength = 8 - strId.length;

    if (paddingLength <= 0) {
      return strId;
    }

    const paddingZeros = "0".repeat(paddingLength);
    return paddingZeros + strId;
  }

  useEffect(() => {
    if (yearSelected) {
      getAttorneyList();
    }
  }, [yearSelected]);

  useEffect(() => {
    const options = [];
    for (let year = 2023; year <= currentYear + 1; year++) {
      options.push({ value: year, label: (year - 1911).toString() });
    }

    setYearOptions(options);

    // 设置预选值为今年
    const currentYearOption = options.find(
      (option) => option.value === currentYear
    );
    setYearSelected(currentYearOption);
  }, []);

  const handleYearChange = (selected) => {
    setYearSelected(selected);
  };

  useEffect(() => {
    if (search != "") {
      let tempStockList;
      if (stocklist != null) {
        tempStockList = stocklist.filter(
          (attorney) =>
            attorney.stock_name.includes(search) ||
            attorney.stock_code.includes(search) ||
            attorney.gift_item.includes(search)
        );
        setSearchStockList(tempStockList);
      }
    } else {
      setSearchStockList([]);
    }
  }, [search]);

  const reLoadWindows = () => {
    window.location.reload();
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? (
      <BiSolidDownArrow className="sort-icon" />
    ) : (
      <BiSolidUpArrow className="sort-icon" />
    );
  };

  const handleAttorneySort = (sortKey) => {
    setStockSortSelect(sortKey);
    handleAttorneySortChange(sortKey);
  };

  const handleAttorneySortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...stocklist];
    let tempSortItemList = [...sortStockList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        if (
          sortKey === "id" ||
          sortKey === "submit_date" ||
          sortKey === "confirm"
        ) {
          sortByKey(tempSortedList, sortKey, content.sortKey);
        } else {
          sortByKey(tempSortedList, sortKey, content.sortKey);
        }

        content.sortKey = nextSort;
        return content.sortKey;
      }
      return null;
    });
    setSortStockList(tempSortItemList);
    setStockList(tempSortedList);
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0;
      });
    } else {
      return array.sort((a, b) => {
        return a[sortKey] > b[sortKey] ? -1 : a[sortKey] < b[sortKey] ? 1 : 0;
      });
    }
  };

  const handelDeleteAttorney = async (notes) => {
    let request = [];
    if (isMultipleVoid) {
      selectedAttorney.forEach((attorney) => {
        request.push({
          id: attorney.id,
          void_note: notes,
        });
      });
    } else {
      request.push({
        id: selectedAttorneyId,
        void_note: notes,
      });
    }
    // request = [{
    //   id: selectedAttorneyId,
    //   void_note: notes
    // }]
    const result = await deleteAttorney(request, localUser.accessToken);
    if (result) {
      reLoadWindows();
    }
  };

  const handleToggleSublist = (attorneyID) => {
    if (expandedRows.includes(attorneyID)) {
      setExpandedRows(expandedRows.filter((id) => id != attorneyID));
    } else {
      setExpandedRows([...expandedRows, attorneyID]);
    }
  };

  const handelToggleAttorney = (attorneyItem) => {
    const index = selectedAttorney.findIndex(
      (item) => item.id === attorneyItem.id
    );
    const tempSelectedAttorney = [...selectedAttorney];
    if (index === -1) {
      tempSelectedAttorney.push(attorneyItem);
      setSelectedAttorney(tempSelectedAttorney);
    } else {
      tempSelectedAttorney.splice(index, 1);
      setSelectedAttorney(tempSelectedAttorney);
    }
  };

  const handelToggleAllAttorney = () => {
    if (selectAllAttorney) {
      setSelectedAttorney([]);
      setSelectAllAttorney(false);
    } else {
      const tempSelectedAttorney = [...selectedAttorney];
      const tempList = searchStockList.length > 0 ? searchStockList : stocklist;
      tempList.forEach((attorney) => {
        const index = selectedAttorney.findIndex(
          (item) => item.stock_code === attorney.stock_code
        );
        if (index == -1 && attorney.confirm === 0) {
          tempSelectedAttorney.push(attorney);
        }
      });
      setSelectedAttorney(tempSelectedAttorney);
      setSelectAllAttorney(true);
    }
  };

  const sumNumbersFromString = (str) => {
    const numbersArray = str.split(",").map(Number);
    const total = numbersArray.reduce((acc, num) => acc + num, 0);
    return total;
  };

  const calculateSumAndDifference = (auto_pic, menul_pic, void_pic) => {
    const numbersArray = [auto_pic, menul_pic, void_pic].map((str) =>
      str.split(",").map(Number)
    );

    const sumArray = numbersArray.reduce(
      (acc, numbers) => numbers.map((num, index) => (acc[index] || 0) + num),
      []
    );

    const thirdNumbers = numbersArray[numbersArray.length - 1];

    const differenceArray = sumArray.map(
      (sum, index) => sum - (thirdNumbers[index] || 0)
    );
    return sumNumbersFromString(differenceArray.join(","));
  };

  const handleOnShowConfirm = async (id) => {
    setShowConfirm(true);
    setSelectedAttorneyId(id);
  };

  const handleOnHideConfirm = () => {
    setShowConfirm(false);
  };

  const handleOnHideVoidInfo = async () => {
    setShowVoidInfo(false);
    setVoidInfo(null);
  };

  const handleOnHideMultipleVoid = async () => {
    setShowMultipleVoid(false);
    setIsMultipleVoid(false);
  };

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
    setSearch(e.target.value);
  }

  function handleSearch() {
    setSearch(searchText);
  }

  function handleKeyPress(e) {
    if (e.which === 13) handleSearch();
  }

  async function getVoidData(attornetItem) {
    const result = await getAttorneyVoidData(
      attornetItem,
      localUser.accessToken
    );
    if (result) {
      setVoidInfo(result);
      setShowVoidInfo(true);
    }
  }

  const handleGetVoidData = (attornetItem) => {
    if (attornetItem.void_amount != 0) {
      getVoidData(attornetItem);
    }
  };

  async function handleDownLoadCSV() {
    const result = await getCSV(
      "attorneySearch",
      yearSelected.label,
      localUser.id,
      "",
      localUser.accessToken
    );
    if (result) {
      const today = new Date();
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      const formattedDate = today
        .toLocaleDateString("zh-TW", options)
        .replace(/\//g, "");

      const blob = new Blob(["\ufeff" + result], {
        type: "text/csv;charset=uft-8",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "attorneyConfirm_output_" + formattedDate + ".csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handleMultipleVoid = () => {
    setShowMultipleVoid(true);
    setIsMultipleVoid(true);
  };

  const handleStockNameNewLine = (name) => {
    const items = [];

    // 将源字符串按照每行 10 个字符的方式拆分成数组
    for (let i = 0; i < name.length; i += 19) {
      const line = name.substring(i, i + 19);
      items.push(line);
    }

    return items.join("\n");
  };

  const renderStockInfo = (attorneyItem) => {
    const stockList = stocklist.filter((stock) => stock.id === attorneyItem.id);
    return stockList[0].stock_info != null
      ? stockList[0].stock_info.map((stock, index) => {
          return (
            <tr className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}>
              <td>{stock.stock_code}</td>
              <td>{stock.stock_name}</td>
              <td>{stock.gift_item}</td>
              <td>{stock.acq_price}</td>
              <td>{stock.auto_pic}</td>
              <td>{stock.manual_pic}</td>
              <td>{stock.void_pic}</td>
              <td>
                {parseInt(stock.auto_pic) +
                  parseInt(stock.manual_pic) -
                  parseInt(stock.void_pic)}
              </td>
              <td>{stock.note}</td>
              <td>
                {stock.acq_all === "1" ? <FaRegCheckSquare /> : <FaRegSquare />}
              </td>
              <td>{padId(stockList[0].id)}</td>
              <td>
                {stockList[0].submit_date.split(" ")[0].split("-").join("/")}
              </td>
              <td
                className={`stock-item ${
                  stockList[0].confirm === 0 ? "not-confirm" : "confirm"
                }`}
              >
                {stockList[0].confirm === 0 ? "未確認" : "已確認"}
              </td>
              <td>
                {stockList[0].confirm === 0 ? (
                  <AiOutlineDelete
                    className="delete-icon"
                    onClick={() => handleOnShowConfirm(stockList[0].id)}
                  />
                ) : (
                  "  "
                )}
              </td>
            </tr>
          );
        })
      : null;
  };

  const renderAttorneyList = () => {
    const renderStockList =
      searchStockList.length != 0
        ? searchStockList
        : search != ""
        ? []
        : stocklist;
    return renderStockList != null
      ? renderStockList.map((attorneyItem, index) => {
          const include = selectedAttorney.some(
            (attorney) => attorney.stock_code === attorneyItem.stock_code
          );
          return attorneyItem.year == yearSelected.label ? (
            <>
              <tr
                className={`hover height-1 ${index % 2 != 0 ? "odd-row" : ""}`}
              >
                <td>{index + 1}</td>
                {attorneyItem.confirm === 0 ? (
                  <td>
                    {include ? (
                      <FaRegCheckSquare
                        onClick={() => handelToggleAttorney(attorneyItem)}
                      />
                    ) : (
                      <FaRegSquare
                        onClick={() => handelToggleAttorney(attorneyItem)}
                      />
                    )}
                  </td>
                ) : (
                  <td></td>
                )}
                <td>{attorneyItem.stock_code}</td>
                <td>{attorneyItem.stock_name}</td>
                <td className="width-50">
                  {attorneyItem.gift_item != null &&
                  attorneyItem.gift_item.length > 19
                    ? handleStockNameNewLine(attorneyItem.gift_item)
                        .split("\n")
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                    : attorneyItem.gift_item}
                </td>
                <td className="width-5">{attorneyItem.acq_price}</td>
                <td className="width-5">{attorneyItem.auto_pic}</td>
                <td className="width-5">{attorneyItem.manual_pic}</td>
                <td
                  className={`width-5 ${
                    attorneyItem.void_pic != 0 ? "button-text" : ""
                  }`}
                  onClick={() => handleGetVoidData(attorneyItem)}
                >
                  {attorneyItem.void_pic}
                </td>
                <td className="padding-10">
                  {parseInt(attorneyItem.auto_pic) +
                    parseInt(attorneyItem.manual_pic) -
                    parseInt(attorneyItem.void_pic)}
                </td>
                <td>
                  {attorneyItem.submit_date.split(" ")[0].split("-").join("/")}
                </td>
                <td className="width-40">{attorneyItem.note}</td>
                <td
                  className={`stock-item ${
                    attorneyItem.confirm === 0 ? "not-confirm" : "confirm"
                  }`}
                >
                  {attorneyItem.confirm === 0 ? "未確認" : "已確認"}
                </td>
                <td>
                  {attorneyItem.confirm === 0 ? (
                    <AiOutlineDelete
                      className="delete-icon"
                      onClick={() => handleOnShowConfirm(attorneyItem.id)}
                    />
                  ) : (
                    "  "
                  )}
                </td>
              </tr>
            </>
          ) : (
            ""
          );
        })
      : "";
  };

  const renderTotalAttorney = () => {
    const renderStockList =
      searchStockList.length != 0
        ? searchStockList
        : search != ""
        ? []
        : stocklist;
    let totalAttorney = 0;
    renderStockList.forEach((item) => {
      totalAttorney +=
        Number(item.auto_pic) + Number(item.manual_pic) - Number(item.void_pic);
    });
    return totalAttorney;
  };

  const renderAutoPic = () => {
    const renderStockList =
      searchStockList.length != 0
        ? searchStockList
        : search != ""
        ? []
        : stocklist;
    let totalAutoPic = 0;
    renderStockList.forEach((item) => {
      totalAutoPic += Number(item.auto_pic) - Number(item.void_pic);
    });
    return totalAutoPic;
  };

  return (
    <>
      <div className="attorney-root">
        <Nav />
        <div className="manager-background">
          <div className="manager-title">查詢</div>
          <div className="main-display">
            <div className="stock-button-bar">
              <MDBInputGroup
                material
                containerClassName="search-block col-12"
                hint="Search.."
                onChange={handleSearchTextChange}
                onKeyPress={handleKeyPress}
                append={
                  <button
                    rounded
                    size="sm"
                    type="submit"
                    className="mr-auto search-button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                }
              />
              <Select
                className="year-select user-level"
                value={yearSelected}
                options={yearOptions}
                onChange={handleYearChange}
              />
              <button className="new-srock-btn" onClick={handleDownLoadCSV}>
                匯出委託書
              </button>
            </div>
            <div className="account-data-form">
              <table>
                <thead>
                  <th>編號</th>
                  <th>
                    {selectAllAttorney ? (
                      <FaRegCheckSquare
                        onClick={() => handelToggleAllAttorney()}
                      />
                    ) : (
                      <FaRegSquare onClick={() => handelToggleAllAttorney()} />
                    )}
                  </th>
                  {stockSortItemList.map((content) => {
                    return (
                      <th
                        className="pointer"
                        onClick={() => {
                          handleAttorneySort(content.name);
                        }}
                      >
                        {content.title}
                        {stockSortSelect === content.name
                          ? renderSortIcon(content.sortKey)
                          : null}
                      </th>
                    );
                  })}
                  <th>功能</th>
                </thead>
                <tbody>{renderAttorneyList()}</tbody>
              </table>
              <div className="selected-count">
                <span>{"委託總份數" + renderTotalAttorney() + "份 "}</span>
                <span className="margin-left-six">
                  {"收購" + renderAutoPic() + "份"}
                </span>
              </div>
              {selectedAttorney.length != 0 ? (
                <div>
                  <button
                    className="submit-button"
                    onClick={handleMultipleVoid}
                  >
                    批量刪除
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Confirm
        show={showConfirm}
        title={"確認"}
        onHide={handleOnHideConfirm}
        message={"確認要取消此委託書上傳嗎?"}
        attorneyInfo={padId(selectedAttorneyId)}
        removeAttorneyUpload={true}
        reLoadWindows={reLoadWindows}
        function={handelDeleteAttorney}
      />
      <Confirm
        show={showVoidInfo}
        title={"退單資訊"}
        onHide={handleOnHideVoidInfo}
        voidInfo={voidInfo}
        reLoadWindows={reLoadWindows}
      />
      <Confirm
        show={showMultipleVoid}
        title={"確認"}
        onHide={handleOnHideMultipleVoid}
        message={"確認要取消已選取委託書上傳嗎?"}
        multipleVoid={selectedAttorney}
        function={handelDeleteAttorney}
        reLoadWindows={reLoadWindows}
      />
    </>
  );
}

export default connect(null, {})(AttorneyConfirmPage);
