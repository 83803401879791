import React, { Component } from 'react';
import { connect } from "react-redux"; 
import Nav from "../../components/Nav"
import "./style.css";

function AboutMePage(props) {
  return(
    <div className='about-me-root'>
      <Nav/>
      <div className="about-me-background">
        <div className="about-me-title">關於我</div>
        <div className="about-me-main-display">
          <div className='about-me-row text-sart'>
            <span className='text'>
              5205
            </span>
            <span className='height-light red margin-left'>
              三大優勢
            </span>
          </div>
          <div className='about-me-row text-sart'>
            <span className='text'>
              代領費，
            </span>
            <span className='height-light red'>
              低
            </span>
          </div>
          <div className='about-me-row text-sart'>
            <span className='text'>
              收購價，
            </span>
            <span className='height-light red'>
              高
            </span>
          </div>
          <div className='about-me-row text-sart'>
            <span className='text'>
              電投  免交單，
            </span>
            <span className='height-light red'>
              有
            </span>
          </div>
          <div className='about-me-row text-sart'>
            <br/><span className='text'>5205 包含以下功能：</span><br/><br/>
            <span className='text margin-1'>1 快速投票</span><br/><br/>
            <span className='text margin-1'>2 交單網站同步匯入已經電投，可委託代領的公司</span><br/><br/>
            <span className='text margin-1'>3 列印多種格式的電投表</span><br/><br/>
            <span className='text margin-1 bold red'>代領費低 + 收購價高 + ５２０５更優惠</span><br/><br/>
            <span className='text margin-1 bold'>給 所有期待零股小確幸的你，有更好的選擇</span><br/><br/><br/>
          </div>
          <div className='about-me-row text-sart'>
            <span className='sub-title blue'>5205—我愛零股—星星雨</span>
          </div>
          <div className='about-me-row text-sart'>
            <span className='text'>從2014年開始，代領全省股東會紀念品</span>
          </div>
          <div className='about-me-row margin-3 text-sart'>
            <span className='sub-title red'>服務項目</span>
          </div>
          <div className='about-me-row service-item text-sart'>
            <div className='text'>1. 不限股數，代領全省股東會紀念品</div>
            <div className='text'>2. 收購紀念品</div>
            <div className='text'>3. 持股管理</div>
            <div className='text'>4. 電投免交單</div>
          </div>
          <div className='about-me-row margin-3 text-sart'>
            <span className='sub-title red '>費用</span>
          </div>
          <div className='about-me-row text-sart'>
            <span className='text blue bold'>1. 代領費</span>
          </div>
          <div className='about-me-row'>
            <table className='about-me-table'>
              <thead className='about-me-thead'>
                <th></th>
                <th>卡</th>
                <th>物</th>
              </thead>
              <tbody>
                <tr>
                  <td>三戶以上</td>
                  <td>7</td>
                  <td>9</td>
                </tr>
                <tr>
                  <td>三戶以下</td>
                  <td>11</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>總份數500份以下</td>
                  <td>11</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>卡未達二成</td>
                  <td>10</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>自行上傳電投截圖</td>
                  <td>8</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>外縣市</td>
                  <td>9</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>大物重物</td>
                  <td></td>
                  <td>11--15</td>
                </tr>
                <tr>
                  <td>份數多的</td>
                  <td colSpan="2">請私訊</td>
                </tr>
                <tr>
                  <td>5205</td>
                  <td colSpan="2">請私訊</td>
                </tr>
                <tr>
                  <td colSpan="3">自行上傳電投圖,收購價每份扣1元</td>
                </tr>
                <tr>
                  <td colSpan="3">{"(重量 ≥ 1公斤 或 衛生紙 或 長寬高和 ≥ 60 cm)"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='about-me-row'>
            <div className='text blue bold'>2. 退單費</div>
            <div className='text'>未電投，重覆領取，非股東，每件加收５元，情節嚴重者，列為黑名單</div>
          </div>
          <div className='about-me-row deliverr-fee'>
            <span className='text blue bold'>3. 運費</span>
            <div>
              <span className='sub-title red'>親取:</span>
              <span className='text'>免運</span>
            </div>
            <div>
              <span className='sub-title red'>貨運:</span>
              <span className='text'>一箱１１５元（三才箱）（新竹貨運）</span>
            </div>
            <div>
              <span className='sub-title red'>店到店:</span>
              <span className='text'>視選擇物流</span>
            </div>
            <div className='margin-3'>
              <span className='text'>歡迎加入</span>
            </div>
            <div>
              <span className='text'>5205 我愛零股 官方社群</span>
            </div>
            <div>
              <span className='text'>或  ID</span>
            </div>
            <div>
              <span className='text'>201830000</span>
            </div>
            <div>
              <span className='text'>或mail</span>
            </div>
            <div>
              <span className='text'>may_y60@hotmail.com</span>
            </div>
            <div >
              <img className='qr-code' src={require("../../assets/images/infoQRcode.jpg")}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  null,
  {  }
)(AboutMePage);