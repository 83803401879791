import React from "react";
import { connect } from "react-redux";
// import { toggleModifier, validateOrder } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react"; 
import { Modal, Button, Form } from "react-bootstrap";
import { addUser } from "../../actions/adminAction";
import { useNavigate } from "react-router-dom"; 
import "../../styles/SignUp.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function SignUp(props) {
  const [state, setState] = useState({ user_email: "", user_login: "", user_pass: "", program_pass: "", nickname: "", telephone: "", user_group: 0, program_fee: 0, delivery_fee: 0});
  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const navigate  = useNavigate(); 

  useEffect(() => {
    window.onpopstate = () => {
      if (document.getElementById("modalConfirm")) {
        props.dismiss();
      }
    };
  }, []);

  useEffect(() => {
    if(props.data && props.data.length != 0) {
      setState({ 
        user_email: props.data === null ? "" : props.data[0].user_email, 
        user_login: props.data === null ? "" : props.data[0].user_login, 
        user_pass: props.data === null ? "" : props.data[0].user_pass, 
        program_pass: props.data === null ? "" : props.data[0].program_pass, 
        nickname: props.data === null ? "" : props.data[0].nickname, 
        telephone: props.data === null ? "" : props.data[0].telephone, 
        program_fee: props.data === null ? "" : props.data[0].program_fee, 
        delivery_fee: props.data === null ? "" : props.data[0].delivery_fee, 
        user_group: props.data === null ? 0 : props.data[0].user_group, 
      })
    }
  }, [props.data]);

  const handleOnHide = () => {
    props.onHide();
  };

  const handleOnSave = async () => {
    props.function(state)
    
  };

  const handelChange = e => {
    
    if(props.data === null && e.target.id === "user_pass") {
      setState(prevState => ({
        ...prevState,
        user_pass: e.target.value,
        program_pass: e.target.value
      }));
    } else {
      setState({ ...state, [e.target.id]: e.target.value });
    }
    
  };

  return (
    <div>
      {props.show && (
      <div className="custom-modal-bg"></div>
    )}
    <Modal show={props.show} onHide={(props.onHide, handleOnHide)} animation={false} size="lg" 
    className="custom-modal" 
    aria-labelledby="contained-modal-title-vcenter"
    centered>
      <Modal.Body>
      <div className="register-background">
        <div className="register-box">
        <h1 className="register-title">{props.title}</h1>
          <Form className="register-form">
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                Email
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="email"
                id="user_email"
                value={state.user_email}
                // placeholder="Enter email"
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                帳號
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="user_login"
                value={state.user_login}
                // placeholder="Enter email"
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                網站密碼
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="user_pass"
                value={state.user_pass}
                // placeholder="Password"
                onChange={handelChange} />
            </Form.Group>
            {props.data !== null ? <Form.Group className="register-group">
              <Form.Label className="register-label">
                5205密碼
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="program_pass"
                value={state.program_pass}
                // placeholder="Password"
                onChange={handelChange} />
            </Form.Group> : null}
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                名字
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="nickname"
                value={state.nickname}
                // placeholder="Enter your name"
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                電話
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="telephone"
                value={state.telephone}
                // placeholder="Enter your name"
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                程式費
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="number"
                id="program_fee"
                value={state.program_fee}
                // placeholder="Enter your name"
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                運費
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="number"
                id="delivery_fee"
                value={state.delivery_fee}
                // placeholder="Enter your name"
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                群組
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="number"
                id="user_group"
                value={state.user_group}
                // placeholder="Enter your name"
                onChange={handelChange} />
            </Form.Group>
          </Form>
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOnHide}>
          取消
        </Button>
        <Button variant="primary" onClick={handleOnSave}>
          {props.submit}
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    
  );
}