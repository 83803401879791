import React, { Component } from 'react';
import { connect } from "react-redux";
import Nav from "../../components/Nav"
import "./style.css";

function PrecautionsPage(props) {
  return(
    <div className='precautions-root'>
      <Nav/>
      <div className="precautions-background">
        <div className="precautions-title">注意事項</div>
        <div className='precautions-display'>
          <p className='text-sart'>
            <span className='height-light red'>委託方式-免交單委託代領</span>
            <br/><br/>
            <span className='text'>
              1. 自行操作5205電投<br/>
              <span className='text'>
                5205網站條列已經完成電投且有發紀念品的公司及數量，自行勾選要交單或收購的公司，批次上傳完成交單委託
              </span>
            </span><br/><br/>
            <span className='text'>
              2. 除極少數一定要通知書才能領取紀念品的公司，其他直接5205網頁上傳交單，不用再上傳議決表，或寄實體通知書 或 議決表
            </span><br/><br/>
            <span className='text'>
              3. 實體開會通知書：以今年為例，有以下幾家公司，只能用開會通知書領取股東會紀念品<br/>
              <span className='text'>台肥，正隆，山隆，華南金，永豐金等等，</span><br/>
              <span className='text'>
                就需要寄 
                <span className='text red bold'>實體開會通知書</span> 
                給我，除此之外，不收實體開會通知書
              </span>
            </span><br/><br/>
            <span className='text'>
              4. 有約一成的公司，領取文件，要求要 
              <span className='red bold'>正本身份證</span>  
              或 
              <span className='red bold'>健保卡</span> 
              或  
              <span className='red bold'>駕照</span> 
              或 
              <span className='red bold'>戶口名簿</span> 
              或 
              <span className='red bold'>戶籍謄本</span>
              <br/>
              每年４／３０停止收受以上文件
            </span><br/><br/>
            <span className='text'>
              5. 有極少數公司，領取文件比較不一樣，需要另外加身份證明影本，或者是議決表
            </span><br/><br/>
            <span className='text'>
              6. 請參考股東會紀念品清冊內的交單方式，正確交單
            </span><br/><br/>
            <span className='text'>
              7. 以下公司，不開放委託，
              <br/>
              <span className='background-yellow'>
                沒發紀念品，正身在無法親自去領的台中以南，可自行ＡＰＰ領取的公司
                <br/>
                （橘子，太魯閣，誠研等等等，全收購不限）
              </span>
            </span><br/><br/>
            <span className='text'>
              8. 在  會員管理內，上傳影本健保卡，駕照，請清楚，不要反光
            </span><br/><br/>
            <span className='text'>
              9. 請在交單截止日前，自行上傳或勾選要交單的公司，和  是否收購
              <br/>
              過期未上傳，
              <span className='background-yellow'>補上傳，每件加收２元</span>
            </span><br/><br/>
            <span className='text'>
              10. 委託代領要不要先付錢？
              <br/>
              <span className='red bold'>不用</span>
              ，最後結算時。再付款
            </span><br/><br/>
            <span className='text'>
              11. 代領後收購要不要再付代領費？
              <br/>
              <span className='red bold'>不用</span>
              ，代領後收購的金額是給付淨額，不用再付代領費。
            </span><br/><br/>
            <span className='text'>
              12. 代領後，收購的錢要怎麼拿到？
              <br/>
              每年過年前，6月15日，最後結清，一年三次轉收購價
            </span><br/><br/>
            <span className='text'>
              13. 公司在發放紀念品時，明文規定（若紀念品不足時，得以等值商品替代），所以不保證拿到的一定跟公告的一樣，收購價是先訂好的，不因替品而變動
            </span><br/><br/>
            <span className='text'>
              14. 全家和711商品卡，限期卡會特別給，其他的不看面額，分別給應發總數
            </span><br/><br/>
            <span className='text'>
              15. 商品卡可抵代領費和運費，全家92折，711（9折），以面額高者，優先折抵
            </span><br/><br/>
            <span className='text'>
              16. 操作5205 電投，edge只能留一個憑證
              <br/>
              <img className='autoVote1' src={require("../../assets/images/autoVote.png")}/>
            </span><br/><br/>
            <span className='text'>
              <span className='bold background-bule'>出貨</span>
              <br/>
              今年開始，十戶以上可選擇  七月貨品較齊，統一出貨，或 滿三箱 宅配出貨
              <br/>
              <span className='text bold'>代領費，最後一箱結算</span>
            </span><br/><br/>
            <span className='text'>
              <span className='bold background-bule'>開戶</span>
              <br/>
              我自己有元大，群益，永豐，華南，犇亞，富邦等券商<br/>
              建議  群益，元大，華南，盤中盤後都是一元手續費<br/>
              群益，除了未成年，其他可全程線上開戶，可自行選擇自己現有的銀行戶當交割戶<br/>
              記得開戶完成，要設定戶頭買額度１萬元，才能預先擋下不小心下成整張的風險
            </span><br/><br/>
            <span className='text'>
              <span className='bold background-bule'>買股</span>
              <br/>
              <span className='text bold red'>買什麼？</span>
              <br/>
              1. 以我領20年的經驗，建議，有發過的，九成都要買，一元手續費不會太痛<br/>
                 除了減資時，要賣，其他都不要動，享受每年零股的小確幸<br/><br/>
              2. 每年在開會前幾個月，公司會公告（未定）不確定要發什麼紀念品，這種我也會補買<br/><br/>
              3. 減資，每年都有少數公司會減資，這種可視往年發放內容，自行決定，是否要補買回來<br/>
                 可參考近６年的股東會紀念品明細 和 建議買進清單<br/><br/>
              <span className='text bold red'>何時買？</span><br/>
              保守一點，最後買進日前一星期<br/>
              每年在開會前幾個月，公司會公告（未定）不確定要發什麼紀念品，<br/>
              最後買進日：常會（開會日前６０天），臨會（開會前３０天）臨時會前３０天<br/>
              每年我會在二月底前，使用本團提供的庫存表，來補買
            </span><br/><br/>
            <span className='text bold'>
              以上，有未盡事宜，請以下聯絡方式
            </span><br/><br/>
            <span className='text background-bule'>
              聯絡方式
            </span><br/><br/>
            <span className='text bold'>
              LINE ID: 201830000
            </span><br/><br/>
            <span className='text'>歡迎加入—５２０５—我愛零股—星星雨團官方社群</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default connect(
  null,
  {  }
)(PrecautionsPage);