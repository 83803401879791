import { S_1_0 } from "../configs/config";

export const login = async  (username, password) => {
  try {
    const response = await fetch(`${S_1_0}/user/login/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: username,
        password: password,
      }),
    });
    if (response.ok) {
      const user = await response.json();
      return user;
    } else {
      response.json().then(responseJson => {
        if(responseJson.code =="4013") {
          window.alert(`此帳號尚未開通 \n 請聯繫客服 \n Line ID：201830000`);
          return null;
        } else {
          window.alert("登入失敗，請檢查電子郵件或密碼")
          return null;
        }
      });
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getUserByLevel = async (level, token) => {
    try {
      const response = await fetch(`${S_1_0}/user/userlist/`, {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({
          level: level,
        }),
      });
      if (response.ok) {
        const userAccountList = [];
        const account = await response.json();
        for(let index=1; index<=account.length; index++) {
          userAccountList.push({
            key: index,
            id: account[index-1].id,
            user_login: account[index-1].user_login,
            user_email: account[index-1].user_email,
            user_pass: account[index-1].user_pass,
            program_pass: account[index-1].program_pass,
            nickname: account[index-1].nickname,
            telephone: account[index-1].telephone,
            account_level: account[index-1].account_level,
            user_registered: account[index-1].user_registered,
            user_group: account[index-1].user_group,
            delivery_fee: account[index-1].delivery_fee,
            program_fee: account[index-1].program_fee,
          })
        }
        return userAccountList;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
};

export const getphyIdList = async (token) => {
  try {
    const response = await fetch(`${S_1_0}/phyid/list/`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const phyIdList = await response.json();
      return phyIdList;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addId = async (idNumber, name, newIdUserId, token) => {
  try {
    const response = await fetch(`${S_1_0}/phyid/addid/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        phy_id_string: idNumber,
        belong_u_id: newIdUserId,
        name: name,
      }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const editId = async (id, belong_u_id, phy_id_string, name, cert_type, seq, token) => {
  try {
    const response = await fetch(`${S_1_0}/phyid/edit/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        id: id,
        phy_id_string: phy_id_string,
        name: name,
        belong_u_id: belong_u_id,
        cert_type: cert_type,
        seq: parseInt(seq)
      }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const removeId = async (id, token) => {
  try {
    const response = await fetch(`${S_1_0}/phyid/delete/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify(
        id
      ),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addUser = async (user) => {
  try {
    const response = await fetch(`${S_1_0}/user/add/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_login: user.user_login,
        user_pass: user.user_pass,
        nickname: user.nickname,
        telephone: user.telephone,
        account_level:2,
        user_email: user.user_email,
        user_group: user.user_group,
    }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getIdListByBelogId = async (id, token) => {
  try {
    const response = await fetch(`${S_1_0}/phyid/userlist/?id=${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const phyIdList = await response.json();
      return phyIdList;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const editUser = async (id, user, token) => {
  try {
    const response = await fetch(`${S_1_0}/user/edit/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        id:id,
        user_email: user.user_email,
        user_login: user.user_login,
        user_pass: user.user_pass,
        program_pass: user.program_pass,
        nickname: user.nickname,
        telephone: user.telephone,
        delivery_fee: user.delivery_fee,
        program_fee: user.program_fee,
        account_level:2,
        user_group: user.user_group,
    }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const uploadPhyPic = async (phyId, file, token) => {
  const fileType = file.type.split("/")[1];
  let formData = new FormData();
  formData.append(
    "fileToUpload[]",
    file
  );
  formData.append(
    "fileInfo",
    JSON.stringify([{
      "type":"phyid",
      "id":phyId.id,
      "FileName":phyId.phy_id_string + "." + fileType,
      "belong_u_id":phyId.belong_u_id
  }]));
  try {
    const response = await fetch(`${S_1_0}/file/upload/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Authorization": "Bearer " + token,
      },
      body: formData
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const getPhyIdPic = async (phyId) => {
  try {
    const url = "https://5205.tw" + phyId.id_pic_path;
    const response = await fetch(url);
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = phyId.id_pic_path.split("/")[4];
      link.click();
    } else {
      console.error('Failed to fetch the file.');
    }
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

export const getCSV = async (mode, year, user_id, shipped_id, token) => {
  try {
    let url = "";
    if(mode === "attorneySearch") {
      url = `${S_1_0}/outputcsv/?mode=${mode}&year=${year}&user_id=${user_id}`;
    } else if(mode === "shipped") {
      url = `${S_1_0}/outputcsv/?mode=${mode}&shipped_id=${shipped_id}`;
    } else {
      url = `${S_1_0}/outputcsv/?mode=${mode}&year=${year}`;
    }
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "text/csv; charset=big5",
        'Accept': 'text/csv',
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const buffer = await response.arrayBuffer();
      const data = new TextDecoder('big5').decode(buffer);
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};