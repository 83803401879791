import React, { Component } from 'react';
import { connect } from "react-redux";
import "./StockManagementPage.css";
import Nav from "../../components/Nav"
import { getStock, removeStock, editStock, addStock, uploadStockPic } from "../../actions/stockAction";
import { getCSV } from "../../actions/adminAction";
import { useNavigate } from "react-router-dom"; 
import Confirm from "../../components/modals/confirm";
import NewStock from "../../components/modals/newStock";
import { setStockList } from "../../actions/"
import { useState, useEffect } from "react"; 
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux/";
import { AiFillEdit, AiOutlineDelete, AiOutlineUpload } from "react-icons/ai";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { MDBBtn, MDBInputGroup } from "mdbreact";
import UploadPhyPic from "../../components/modals/uploadPhyPic";

function StockManagementPage(props) {
  const longSortItemList = [
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "gift_item", sortKey: "down", title: "紀念品" },
    { name: "note", sortKey: "down", title: "交單備註" },
    { name: "agent", sortKey: "down", title: "股代" },
    { name: "deadline", sortKey: "down", title: "委託截止日" },
    { name: "meeting_time", sortKey: "down", title: "開會時間" },
    { name: "behalf_price", sortKey: "down", title: "代領費" },
    { name: "acq_price", sortKey: "down", title: "收購價" },
    { name: "finalbuy_time", sortKey: "down", title: "最後買進日" },
    { name: "bypass_notification", sortKey: "down", title: "免交單" },
  ];
  const stock = useSelector((state) => state.stockReducer.stock);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ showConfirmAll, setShowConfirmAll ] = useState(false);
  const [ showNewStock, setShowNewStock ] = useState(false);
  const [ showEditStock, setShowEditStock ] = useState(false);
  const [ removeUserIdInfo, setRemoveUserIdInfo ] = useState([]);
  const [ selectedIds, setSelectedIds ] = useState([]);
  const [ sortSelect, setSortSelect ] = useState("stock_code");
  const [ sortItemList, setSortItemList ] = useState(longSortItemList);
  const [ sortedList, setSortedList ] = useState(stock || []);
  const [ selectAll, setSelectAll ] = useState(false);
  const [ state, setState ] = useState({ behalf_price: 0, deadline: 0, acq_price: 0, gift_item: "", note: "", gift_category:0});
  const [ yearSelected, setYearSelected ] = useState(null);
  const [ yearOptions, setYearOptions ] = useState([]);
  const [ selectedStock, setSelectedStock ] = useState(null);
  const [ searchText, setSearchText ] = useState("");
  const [ search, setSearch ] = useState("");
  const [ searchSortedList, setSearchSortedList ] = useState([]);
  const [ showUploadPic, setShowUploadPic ] = useState(false);
  const [ uploadPicStock, setUploadPicStock ] = useState([]);
  
  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const navigate  = useNavigate(); 

  async function getStockList() {
    const result = await getStock();
    if(result != null ) {
      let showStockList = [];
      for(let i=0; i<result.length; i++) {
        let year = result[i].meeting_time.split("-")[0];
        if(year == yearSelected.value) {
          showStockList.push(result[i]);
        }
      }
      setSortedList(showStockList);
      dispatch(setStockList(showStockList));
    }
  }

  useEffect(() => {
    const options = [];
    
    for (let year = 2023; year <= currentYear + 1; year++) {
      options.push({ value: year, label: (year - 1911).toString() });
    }

    setYearOptions(options);

    // 设置预选值为今年
    const currentYearOption = options.find((option) => option.value === currentYear);
    setYearSelected(currentYearOption);
  }, []);


  useEffect(() => {
    if(yearSelected != null) {
      getStockList();
      setSelectAll(false);
      setSelectedIds([]);
    }
  }, [yearSelected]);

  useEffect(() => {
    if (stock && !sortedList.length) {
      setSortedList(stock);
    }
  }, [sortedList, stock]);

  useEffect(() => {
    if(search != "") {
      let tempSortedList;
      if(sortedList != null) {
        tempSortedList = sortedList.filter(stock => stock.stock_code.includes(search) || stock.stock_name.includes(search) || stock.agent.includes(search) || stock.gift_item.includes(search) || stock.note.includes(search));
        setSearchSortedList(tempSortedList);
      }
    } else {
      setSearchSortedList([]);
    }
  }, [search, sortedList])

  function handleOnShowConfirm(stockItemId) {
    setShowConfirm(true);
    const idExists = removeUserIdInfo.some(item => item.id === stockItemId);
    if(!idExists) {
      setRemoveUserIdInfo([...removeUserIdInfo, {id: stockItemId}]);
    }
  };

  const handleOnShowConfirmAll = () => {
    setShowConfirmAll(true);
  };

  const handleOnHideConfirm = () => {
    setRemoveUserIdInfo([])
    setShowConfirm(false);
  };
  const handleOnHideConfirmAll = () => {
    setShowConfirmAll(false);
  };

  const handelRemoveStock = async () => {
    const result = await removeStock(removeUserIdInfo, localUser.accessToken);
    if(result) {
      handleOnHideConfirm();
      getStockList();
    }
  }

  const toggleSelection = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prevIds) => prevIds.filter((selectedId) => selectedId != id));
    } else {
      setSelectedIds((prevIds) => [...prevIds, id]);
    }
    if(selectAll) {
      setSelectAll(false);
    }
  };

  const toggleAllSelection = () => {
    if(searchText != "") {
      for(let i=0; i<searchSortedList.length; i++) {
        if(!selectAll) {
          if(!selectedIds.includes(searchSortedList[i].id)) {
            toggleSelection(searchSortedList[i].id);
          }
        } else {
          setSelectedIds([]);
        }
      }
    } else {
       for(let i=0; i<sortedList.length; i++) {
        if(!selectAll) {
          if(!selectedIds.includes(sortedList[i].id)) {
            toggleSelection(sortedList[i].id);
          }
          
        } else {
          setSelectedIds([]);
        }
      }
    }
   
    
    if(selectAll) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  }

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
      });
    }
    else {
      return array.sort((a, b) => {
        return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
      });
    }
  }

  const handleSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...sortedList];
    let tempSortItemList = [...sortItemList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortItemList(tempSortItemList);
    setSortedList(tempSortedList);
  }

  const handleSort = (sortKey) => {
    setSortSelect(sortKey);
    handleSortChange(sortKey)
  }

  const handelRemoveAllStock = async () => {
    const reBuildList = [];
    for(let i = 0; i< selectedIds.length; i++) {
      reBuildList.push({id: selectedIds[i]});
    }
    const result = await removeStock(reBuildList, localUser.accessToken);
    if(result) {
      handleOnHideConfirmAll();
      getStockList();
    }
  }

  const handelNewStock = async (stock) => {
    const result = await addStock(stock, currentYear, localUser.accessToken);
    if(result) {
      handleOnHideNewStock();
      getStockList();
    }
  }

  const handelChangeStock = async (stock) => {
    const result = await editStock([{id: selectedStock[0].id}], stock, localUser.accessToken);
    if(result) {
      handleOnHideEditStock();
      getStockList();
    }
  }

  async function handelUploadFile(file) {
    const result = await uploadStockPic(uploadPicStock, file, localUser.accessToken);
    if(result) {
      handelOnHideUploadStockPic();
      getStockList();
    } else {
      window.alert("file upload fail");
    }
  }

  const handelChange = e => {
    switch (e.target.id) {
      case "behalf_price":
      case "acq_price":
      case "deadline":
      case "gift_category":
        setState({ ...state, [e.target.id]: e.target.valueAsNumber });
        break;
    
      default:
        setState({ ...state, [e.target.id]: e.target.value });
        break;
    }
    
  };

  const handelSubmitChange = async e => {
    const reBuildList = [];
    let updateFields;
    let result;
    for(let i = 0; i< selectedIds.length; i++) {
      reBuildList.push({id: selectedIds[i]});
    }

    switch (e.target.id) {
      case "behalf_price":
        updateFields = {
          behalf_price: 0
        };
        updateFields.behalf_price = state.behalf_price;
        result = await editStock(reBuildList, updateFields, localUser.accessToken);
        break;
      case "acq_price":
        updateFields = {
          acq_price: 0
        };
        updateFields.acq_price = state.acq_price;
        result = await editStock(reBuildList, updateFields, localUser.accessToken);
        break;
      case "deadline":
        updateFields = {
          deadline: ""
        };
        updateFields.deadline = state.deadline;
        result = await editStock(reBuildList, updateFields, localUser.accessToken);
        break;
      case "gift_item":
        updateFields = {
          gift_item: ""
        };
        updateFields.gift_item = state.gift_item;
        result = await editStock(reBuildList, updateFields, localUser.accessToken);
        break;
      case "note":
          updateFields = {
            note: ""
          };
          updateFields.note = state.note;
          result = await editStock(reBuildList, updateFields, localUser.accessToken);
          break;
      case "gift_category":
          updateFields = {
            gift_category: 0
          };
          updateFields.gift_category = state.gift_category;
          result = await editStock(reBuildList, updateFields, localUser.accessToken);
          break;
      default:
        break;
    }
    if(result) {
      setSelectedIds([]);
      setState({ behalf_price: 0, deadline: 0, acq_price: 0, gift_item: "", note: "", gift_category:0});
      getStockList();
    }
  };

  const handleYearChange = (selected) => {
    setYearSelected(selected);
  };

  const handekShowNewStock = () => {
    setShowNewStock(true);
  }

  const handleOnHideNewStock = () => {
    setShowNewStock(false);
  }

  const handelShowEditStock = (id) => {
    const selectedStock = sortedList.filter(stock => stock.id === id);
    setSelectedStock(selectedStock);
    setShowEditStock(true);
  }

  const handleOnHideEditStock = () => {
    setShowEditStock(false);
  }

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
    setSearch(e.target.value);
  }

  function handleSearch() {
    setSearch(searchText);
  }

  function handleKeyPress(e) {
    if(e.which === 13) 
    handleSearch();
  }

  function formateTime(time) {
    const originalDate = new Date(time);

    // 提取年、月、日
    const year = originalDate.getFullYear().toString().slice(2); // 提取后两位
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始
    const day = originalDate.getDate().toString().padStart(2, '0');

    // 格式化为 "YY/MM/DD" 的字符串
    const formattedDateString = `${year}/${month}/${day}`;
    if(time == "0000/00/00") {
      return("00/00/00");
    } else {
      return(formattedDateString);
    }
  };

  async function handleDownLoadCSV() {
    const result = await getCSV( "stock", yearSelected.label, localUser.id, "", localUser.accessToken);
    if(result) {
      const today = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      const formattedDate = today.toLocaleDateString('zh-TW', options).replace(/\//g, '');
      
      const blob = new Blob(["\ufeff" +result], { type: 'text/csv;charset=uft-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'stocklist_output_' + formattedDate + '.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handelPicUpload = (stock) => {
    setUploadPicStock(stock);
    setShowUploadPic(true);
  }

  const handelOnHideUploadStockPic = () => {
    setShowUploadPic(false);
    setUploadPicStock([]);
  }

  const handleShowStockImg = (url) => {
    // 在新标签页中打开链接
    if(url != null) {
      window.open('https://5205.tw' + url);
    }
  };

  const handleStockNameNewLine = (name) => {
    const items = [];

    // 将源字符串按照每行 10 个字符的方式拆分成数组
    for (let i = 0; i < name.length; i += 20) {
      const line = name.substring(i, i + 20);
      items.push(line);
    }

    return items.join('\n');
  }

  const renderStockEdit = () => {
    return(
      <div className="edit-form">
        <div className="edit-block">
          <span>代領單價: </span>
          <div>
            <input type="number" min={0} id="behalf_price" value={state.behalf_price} onChange={handelChange}/>
            <button className="stock-edit-botton" id="behalf_price" onClick={handelSubmitChange}>修改選取項目</button>
          </div>
        </div>
        <div className="edit-block">
          <span>收購紀念品單價: </span>
          <div>
            <input type="number" min={0} id="acq_price" value={state.acq_price} onChange={handelChange}/>
            <button className="stock-edit-botton" id="acq_price" onClick={handelSubmitChange}>修改選取項目</button>
          </div>
        </div>
        <div className="edit-block">
          <span>更新截止日為開會前: </span>
          <div>
            <input type="number" min={0} id="deadline" value={state.deadline}  onChange={handelChange}/>
            <span>天</span>
            <button className="stock-edit-botton" id="deadline" onClick={handelSubmitChange}>修改選取項目</button>
          </div>
        </div>
        <div className="edit-block">
          <span>更新紀念品為: </span>
          <div>
            <input type="text" id="gift_item" value={state.gift_item} onChange={handelChange}/>
            <button className="stock-edit-botton" id="gift_item" onClick={handelSubmitChange}>修改選取項目</button>
          </div>
        </div>
        <div className="edit-block">
          <span>更新備註為: </span>
          <div>
            <input type="text" id="note" value={state.note} onChange={handelChange}/>
            <button className="stock-edit-botton" id="note" onClick={handelSubmitChange}>修改選取項目</button>
          </div>
        </div>
        <div className="edit-block">
          <span>更新紀念品類型為: </span>
          <div>
            <input type="number" id="gift_category" value={state.gift_category} onChange={handelChange}/>
            <button className="stock-edit-botton" id="gift_category" onClick={handelSubmitChange}>修改選取項目</button>
          </div>
        </div>
      </div>
    )
  }

  const renderStockList = () => {
    const renderStockList = searchSortedList.length != 0 ? searchSortedList : search != "" ? [] : sortedList;
    return(
      renderStockList != null ? 
        renderStockList.map((stockItem, index) => {
          const handleOnDelete = () => {
            handleOnShowConfirm(stockItem.id);
          }
          const handleOnEdit = () => {
            handelShowEditStock(stockItem.id);
          }
          let url = "https://doc.twse.com.tw/server-java/t57sb01?step=1&colorchg=1&co_id=" + stockItem.stock_code.split("x")[0] + "%20%20%20%20%20%20&year=" + stockItem.year + "&mtype=F&";
          return(
            <tr key={index} className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}>
              <td>{index + 1}</td>
              {localUser && localUser.account_level === 1 ?
              <td className="stock-title stock-icon-btn select-colum">  
                {selectedIds.includes(stockItem.id) ? <FaRegCheckSquare className="stock-select-icon"  onClick={ () => toggleSelection(stockItem.id)}/> : <FaRegSquare className="stock-select-icon"  onClick={ () => toggleSelection(stockItem.id)}/>}
              </td>
               : null}
              <td><a className='stock_code_click bold' href={url} target="_blank">{stockItem.stock_code}</a></td>
              <td className={`${stockItem.note.includes("正身") || stockItem.note.includes("正文") ? "text_red bold" : ""}`}>{stockItem.stock_name}</td>
              <td className={`${stockItem.gift_pic_path != null ? "pointer button-text" : ""}`} onClick={() => handleShowStockImg(stockItem.gift_pic_path)}>
                {stockItem.gift_item.length > 20 ? handleStockNameNewLine(stockItem.gift_item).split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                )) : stockItem.gift_item}
              </td>
              <td>{stockItem.note}</td>
              <td>{stockItem.agent}</td>
              <td>{formateTime(stockItem.deadline.split(" ")[0].split("-").join("/"))}</td>
              <td>{formateTime(stockItem.meeting_time.split(" ")[0].split("-").join("/"))}</td>
              <td>{stockItem.behalf_price}</td>
              <td>{stockItem.acq_price}</td>
              <td>{formateTime(stockItem.finalbuy_time.split(" ")[0].split("-").join("/"))}</td>
              <td>{stockItem.bypass_notification === 1 ? "是" : "否"}</td>
              {localUser && localUser.account_level === 1 ?
              <td> 
                <div className='stock-icon'>
                  <AiOutlineDelete  className="delete-icon" onClick={handleOnDelete}/>
                  <AiFillEdit className="select-icon" onClick={handleOnEdit}/>
                  <AiOutlineUpload className="upload-icon" onClick={() => handelPicUpload(stockItem)} />
                </div> 
              </td> : ""}
            </tr>
          )
        })
    : "")
  }

  return (
    <div className='stock-manager-root'>
      <Nav/>
      <div className="stock-manager-background">
        <div className="stock-manager-title">紀念品</div>
        <div className="stock-main-display">
        {localUser && localUser.account_level === 1 ? renderStockEdit() : "" }
          {localUser && localUser.account_level === 1 ? 
            <div className="stock-button-bar">
              <MDBInputGroup
                material
                containerClassName="search-block col-12"
                hint="Search.."
                onChange={handleSearchTextChange}
                onKeyPress={handleKeyPress}
                append={
                  <button
                    rounded
                    size="sm"
                    type="submit"
                    className="mr-auto search-button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                }
              />
              <Select className="year-select" menuPlacement="top" value={yearSelected} options={yearOptions} onChange={handleYearChange}/>
              <button className="new-srock-btn" onClick={handleDownLoadCSV}>
                匯出紀念品列表
              </button>
              <button className="new-srock-btn" onClick={handekShowNewStock}>
                新增
              </button>
            </div> : 
            <div className="stock-button-bar-user">
              <MDBInputGroup
                material
                containerClassName="search-block col-12"
                hint="Search.."
                onChange={handleSearchTextChange}
                onKeyPress={handleKeyPress}
                append={
                  <button
                    rounded
                    size="sm"
                    type="submit"
                    className="mr-auto search-button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                }
              />
              <Select className="year-select user-level" menuPlacement="top" value={yearSelected} options={yearOptions} onChange={handleYearChange}/>
              {localUser ? <button className="new-srock-btn" onClick={handleDownLoadCSV}>
                匯出紀念品列表
              </button> : ""}
            </div>}
          <div className="stock-data-form">
            <table>
              <thead>
                <tr>
                  <th className='stock-title'>編號</th>
                  {localUser && localUser.account_level === 1 ? 
                  <th className="stock-title stock-icon-btn">  {selectAll ? <FaRegCheckSquare className="stock-select-icon"  onClick={() => toggleAllSelection()}/> : <FaRegSquare className="stock-select-icon"  onClick={() => toggleAllSelection()}/>} </th>
                  : null}
                  {longSortItemList.map((content, index) => {
                    return(
                      <th key={index} className='stock-title' onClick={() => { handleSort(content.name) }}>{content.title}{sortSelect === content.name ?
                        renderSortIcon(content.sortKey)
                        : null}</th>
                    )
                  })}
                  {localUser && localUser.account_level === 1 ? <th>功能</th> : ""}
                </tr>
              </thead>
              <tbody>
                {renderStockList()}
              </tbody>
            </table>
          </div>
          {selectedIds.length != 0 ?
          <div>
            <button className="delete-srock-btn" onClick={handleOnShowConfirmAll}>刪除選擇項目</button>
          </div> : ""}
        </div>
      </div>
      <Confirm
        show={showConfirm}
        onHide={handleOnHideConfirm}
        message={"確認要刪除此股票資訊嗎?"}
        function= {handelRemoveStock}/>
      <Confirm
        show={showConfirmAll}
        onHide={handleOnHideConfirmAll}
        message={"確認要刪除所選擇的股票資訊嗎?"}
        function= {handelRemoveAllStock}/>
      <NewStock
        show={showNewStock}
        onHide={handleOnHideNewStock}
        data={null}
        submit="新增"
        function= {handelNewStock}/>
      <NewStock
        show={showEditStock}
        onHide={handleOnHideEditStock}
        data={selectedStock}
        submit="修改"
        function= {handelChangeStock}/>
      <UploadPhyPic
        show={showUploadPic}
        onHide={handelOnHideUploadStockPic}
        stock={uploadPicStock}
        function={handelUploadFile}/>
    </div>
  )
}

export default connect(
  null,
  { getStock, removeStock, editStock, addStock }
)(StockManagementPage);